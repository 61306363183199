const path = location.hostname === 'staging.livas.lt' ? '' : '/_dev';

export const environment = {
  production: true,
  oauth_id: 'DFCE1imiTddXQvFfIVf0D5wuhNeVq8DUSlBQWs23',
  oauth_secret:
    'mBxiABY2mZmIUbn8Ds2qNzrZJP0OjZ5N3AmNIUXjNixmDeeS10msbHmZkj4lhoqQqgs6psyasbRdd4ffokAv09otKaWXjoXagBjpGMWpwWznEix3y8hnQzeMMMkK4j5B',
  url: 'https://boris-staging-api.workis.lt',
  livasAPIBase: path,
  livasAPIBaseLT: 'https://edla.biuro.lt/_dev',
  livasAPIBaseLV: 'https://edla.biuro.lt/_dev',
  livasAPIBaseEE: 'https://edla.biuro.lt/_dev',
  livasAPIBaseLTV3: `https://edla.biuro.lt${path}/lt/jobs/api/v3`,
  livasAPIBaseLVV3: `https://edla.biuro.lt${path}/lv/jobs/api/v3`,
  livasAPIBaseEEV3: `https://edla.biuro.lt${path}/ee/jobs/api/v3`,
  livasAPIBaseGiGroupV3: `${path}/gi-group/jobs/api/v3`,
  edlaAPIBase: 'https://edla.biuro.lt/_dev',
  env: 'staging',
};
